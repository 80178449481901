<template>
  <div v-if="loading" class="col-sm-12" style="text-align: center">
    <i class="fas fa-spinner fa-spin fa-7x"></i>
  </div>
  <div v-else class="container card-group c-grid-block">
    <div class="col-sm-3">
          <img class="card-img-top" :src="result.image" alt="Card image cap">
     </div>
    <div class="col-sm-9 row align-content-center">
      <div class="card-body">
        <h6 class="card-subtitle text-50 ">Album</h6>
        <h3 class="card-title">{{ result.name }}</h3>
        <h6 class="card-subtitle text-50">{{ result.artist.name }}</h6>
      </div>

    </div>
    <div class="col-sm-12">
      <div class="table table-sm table-hover">
        <tbody>
        <tr v-for="track in result.track_objects">
          <td>
            <div class="text-center">
              {{track.track_number}}
            </div>
          </td>
          <td class="col-sm-7">
            {{ track.track.name }}
          </td>
          <td>
            {{formatTime(track.track.duration)}}
          </td>
          <td>
            <span v-if="isInQueue(track.track.track_id)">
                                   <a class="btn btn">
                                   <i class="fas fa-check" style="color: green"></i>
                                   </a>
                                </span>
            <a class="btn btn" v-else @click="addTrack(track.track.track_id)">
              <i class="fa fa-plus text-100" ></i></a>
          </td>
        </tr>
        </tbody>

      </div>

    </div>

    </div>

 </template>

<script>
export default {
  name: 'SearchAlbum',
  components: {},

  /** Lifecycle Events - Wird ausgeführt wenn die Seite geladen (created) wird **/
  created: async function () {
    this.onSearch()
  },

  /** Daten welche der View <template> zur Verfügung gestellt werden **/
  data: function() {
    return {
      loading: true,
      result: {}
    }
  },
  watch: {
    //  '$route.params.query': function () {
    //  this.onSearch()
    // },
    '$route.params.album_id': function () {
      this.onSearch()
    }
  },

  /** Methoden welche aufgerufen werden können
   * Entweder im <script> mit this.methodName()
   * Oder im Template
   */
  methods: {

    getAlbumfromAlbum_id (album_id){
      const album = this.result.albums.find(function (element){
        return element.album_id === album_id
      })
      return album
    },



    async onSearch () {
      const searchText = this.$route.params.query
      const albumId = this.$route.params.album_id

      this.loading = true
      //  const result = await axios.get('/data/search?query=' + searchText)
     // const result = await axios.get('/data/artist/' + artistId)
       const result = await axios.get('/data/album/' + albumId)
      this.result = result.data
      this.loading = false
    }
  }
}
</script>

<style scoped>


.table td {
  vertical-align: middle;
}

.card-body {
  padding: 1rem;


}


.c-grid-block {
  background-color: rgba(126, 186, 118,0.15);
  color: #000000;


}

.table-hover tbody tr:hover td {
  background-color: rgba(69, 153, 55, 0.2);
}


.table-sm {
  color: #0b1f0b;
  padding-bottom: 0.1rem;
}
.table tr {
  height: 1rem;
}

.card-deck .card {
  margin-bottom: 1rem;
}
.card-transparent{

}

.card-transparent:hover{
  background-color: rgba(69, 153, 55, 0.2);
}
.table-hover tbody tr:hover td {
  background-color: rgba(69, 153, 55, 0.2);
}

.card:hover {
  background-color: rgba(69, 153, 55,0.2);
}
.card-img-top{
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.1);
  min-width: 100%;
  object-fit: cover;
  margin-top: 2rem;
  margin-right: 1rem;
  margin-bottom: 2rem;


}
.text-100 {
  color: #000000;
}

.text-50 {
  color: rgba(0,0,0,0.5);
  font-weight: inherit;
}
.card-deck .card {
  border: 1rem solid #646464;

}

.card {
  background: #646464;
  border-radius: 0px;
  border: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

</style>

