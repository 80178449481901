<template>
    <div class="app">
        <nav class="navbar navbar-expand-md navbar-dark fixed-top">
            <a class="navbar-brand" href="/" role="button">ManyDJs {{roomName}}</a>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <router-link to="/queue" class="nav-link">Wiedergabeliste</router-link>
                    </li>
                    <li class="nav-item active">
                        <router-link to="/waitlist" class="nav-link">Warteliste</router-link>
                    </li>
                    <li class="nav-item active">
                      <router-link to="/clients" class="nav-link">Clients</router-link>
                    </li>
                    <li class="nav-item active">
                        <router-link to="/playlists" class="nav-link">Playlist laden</router-link>
                    </li>
                    <li class="nav-item active" v-if="displayLink">
                        <a :href="displayLink" target="_blank" class="nav-link">Anzeige öffnen</a>
                    </li>
                </ul>
                <form autocomplete="off" class="form-inline mt-2 mt-md-0" v-if="hasRoom">
                    <input id="MyInput" class="form-control mr-sm-2" v-model="searchText" type="text"
                           placeholder="Suchen" aria-label="Search">
                    <button class="btn btn-secondary " @click.prevent="search" type="submit">Suchen</button>
                </form>
            </div>
        </nav>
        <main role="main" class="container-fluid">
            <router-view/>

            <div id="updateError" v-if="updateError">
                Achtung! Fehler beim Aktualisieren der Spotify Playlist!
            </div>
        </main>
        <div class="test">
            <img class="logo-side2" src="./assets/ManyDJ_Logo.png"/>
        </div>
    </div>
</template>
<script>


    export default {

        methods: {
            updateData: async function () {
                if (this.$store.state.hostid) {
                    // reload
                    try {
                        const state = await axios.get('/host/status')

                        if (state.data.error === 'no-host' && localStorage.getItem('host_id')) {
                            // session expired but local session present
                            localStorage.removeItem('host_id');
                            this.$router.push('/');
                        }

                        document.title = state.data.host.name + " - ManyDJs"
                        this.$store.commit('setStatus', state.data)
                        // this.$store.commit('setQueue', state.data.queue)
                    } catch (err) {
                        console.log(err)
                    }
                    window.setTimeout(this.updateData, 2500)
                } else {
                    // set timeout till we have a hostid
                    window.setTimeout(this.updateData, 1000)
                }
            },
            updatePlaylist: async function () {
                try {
                    const response = await axios.get('/host/update')

                    window.setTimeout(this.updatePlaylist, 5000)
                    this.updateError = false;
                } catch (e) {
                    this.updateError = true;
                    window.setTimeout(this.updatePlaylist, 15000)
                }
            },
            search: function () {
                this.$router.push('/search/' + encodeURIComponent(this.searchText))
            }
        },
        mounted () {
            this.updateData()
            this.updatePlaylist()

            var hostId = localStorage.getItem('host_id')

            if (hostId) {
                this.$store.commit('setHostId', hostId)
            }
        },
        data: function () {
            return {
                searchText: '',
                updateError: false,
            }
        },
        computed: {
            roomState: function () {
                return this.$store.state.roomState
            },
            hasRoom: function () {
                return this.$store.state.hostid !== null
            },
            roomName: function () {
                return this.$store.state.roomState.host ? this.$store.state.roomState.host.name : ''
            },
            displayLink: function () {
                return this.$store.state.roomState.host ? process.env.VUE_APP_SERVER_URL + 'queue?hash=' + this.$store.state.roomState.host.hash : ''
            },
        }

    }

</script>

<style lang="stylus">

    .app {
        margin-top: 6rem;
    }

    .navbar {
        background: #459937;
    }

    .test {

    }

    #updateError {
        position: absolute;
        width: 700px;
        top: 80px;
        left: 50%;
        z-index: 1000;
        background-color: #e56262;
        margin-left: -350px;
        padding: 30px 10px;
        font-weight: bold;
    }

    .logo-side2 {
        position: absolute;
        right: 0;
        top: 0;
        width: 60rem;
        object-fit: cover;
        object-position: 20rem 1rem;
        z-index: -100;
        opacity: 0.1;
    }

</style>

