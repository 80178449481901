<template>
    <div class="container">
        <template v-if="! isRegistered">
            <div class="form-group row createRoom">
                <div class="col-sm-12 text-center">
                    <h2>Erstelle einen Raum</h2>
                    <input type="text" v-model="roomName" class="form-control" placeholder="Name">
                    <button type="submit" @click="createRoom" class="btn btn-lg btn-success">Raum erstellen</button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-sm-8">
                    <h3>Raum Einstellungen</h3>
                    <form>
                        <div class="form-group row">
                            <label for="inputEmail3" class="col-sm-3 col-form-label">Raumname</label>
                            <div class="col-sm-9">
                                <input type="text" readonly v-model="roomName" class="form-control" placeholder="Name" id="inputEmail3">
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <div class="col-sm-3" style="vert-align: middle">
                                <div class="label">Einstellungen
                                </div>
                            </div>
                            <div class="col-sm-3">
                              <label class="switch">
                                <input  type="checkbox" id="openRoom" v-model="roomSettings.roomOpen">
                                <span class="slider round"></span>
                              </label>
                                <label for="openRoom" style="margin-left: 1rem"> Raum offen </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-9 offset-3">
                                <label for="timeout">Gespielte Songs werden für:</label>
                                <select class="form-control" name="timeout" id="timeout" v-model="roomSettings.songTimeout">
                                    <option value="10">10 min</option>
                                    <option value="30">30 min</option>
                                    <option value="60">60 min</option>
                                    <option value="90">1.5 h</option>
                                    <option value="120">2 h</option>
                                    <option value="240">4 h</option>
                                    <option value="480">8 h</option>
                                    <option value="1440">24 h</option>
                                    <option value="99999999">Nie freischalten</option>
                                </select>
                              gesperrt.
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <div class="col-sm-9 offset-3">
                                <label for="timeout">Songs löschen alle:</label>
                                <select class="form-control" name="timeout" id="timeout" v-model="roomSettings.autoDeleteInterval">
                                    <option value="10">10 min</option>
                                    <option value="30">30 min</option>
                                    <option value="60">60 min</option>
                                    <option value="99999999">Nie löschen</option>
                                </select>
                              gesperrt.
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-9 offset-3">
                                <label for="timeout">Songs löschen mit weniger als:</label>
                                <select class="form-control" name="timeout" id="timeout" v-model="roomSettings.autoDeleteThreshold">
                                    <option value="1">1 Vote</option>
                                    <option value="2">2 Votes</option>
                                    <option value="3">3 Votes</option>
                                    <option value="4">4 Votes</option>
                                    <option value="5">5 Votes</option>
                                    <option value="6">6 Votes</option>
                                    <option value="7">7 Votes</option>
                                    <option value="8">8 Votes</option>
                                    <option value="9">9 Votes</option>
                                    <option value="10">10 Votes</option>
                                    <option value="99999999">Nie löschen</option>
                                </select>
                              gesperrt.
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <div class="col-sm-3" style="vert-align: middle">
                                <div class="label">Berechtigungen
                                </div>
                            </div>
                            <div class="col-sm-9">
                              <div>
                                <label class="switch">
                                  <input  type="checkbox" id="allowVote" v-model="roomSettings.allowVote">
                                  <span class="slider round"></span>
                                </label>
                                <label for="allowVote" style="margin-left: 1rem"> Für Songs abstimmen  </label>
                              </div>
                              <div>
                                <label class="switch">
                                  <input  type="checkbox" id="allowAdd" v-model="roomSettings.allowAdd">
                                  <span class="slider round"></span>
                                </label>
                                <label for="allowAdd" style="margin-left: 1rem"> Songs hinzufügen </label>
                              </div>
                              <div>
                                <label class="switch">
                                  <input  type="checkbox" id="autoConfirm" v-model="roomSettings.autoConfirm">
                                  <span class="slider round"></span>
                                </label>
                                <label for="autoConfirm" style="margin-left: 1rem"> Songs automatisch freischalten </label>
                              </div>

                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <div class="col-sm-3" style="vert-align: middle">
                                <div class="label">Raum löschen
                                </div>
                            </div>
                            <div class="col-sm-9">
                                Achtung! Diese Aktion löscht den Raum unwiederruflich!<br /><br />
                                <button class="btn btn-danger" @click="closeRoom">Raum löschen</button>
                            </div>
                        </div>
                        <hr/>
                    </form>
                </div>
                <div class="col-sm-4">
                    <h3>Gäste einladen</h3>
                    <a style="color: #01b301" :href="inviteLink" target="_blank">Einladungslink</a><br/><br />

                    <h4>Code: <span class="code">{{ inviteCode }}</span></h4>

                    <vue-qrcode :value="inviteLink" :width="400" :quality="1" v-if="inviteLink"/>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
    // @ is an alias to /src
    import VueQrcode from 'vue-qrcode'
    import dateformat from 'dateformat'

    export default {
        name: 'Home',
        components: {
            VueQrcode
        },
        mounted: async function () {
            if (this.$route.query['host_id']) {
                localStorage.setItem('host_id', this.$route.query['host_id'])
            }

            var hostId = localStorage.getItem('host_id')

            if (hostId) {
                this.isRegistered = true

                const settings = await axios.get('/host/settings')

                this.roomSettings = settings.data
            }
        },
        watch: {
            '$store.state.roomState.host.name': function () {
                this.roomName = this.$store.state.roomState.host.name
            },
            'roomSettings': {
                deep: true,
                handler: async function () {
                    const response = await axios.post('/host/settings', {settings: this.roomSettings});

                    console.log(response);
                }
            }
        },
        methods: {
            createRoom: function () {
                var data = {
                    roomName: this.roomName
                }

                var urlString = Object.keys(data).map(function (k) {
                    return encodeURIComponent(k) + "=" + encodeURIComponent(data[k])
                }).join('&')

                window.location.href = process.env.VUE_APP_SERVER_URL + 'host/create?' + urlString
            },
            closeRoom: async function() {
                if (window.confirm("Wenn der Raum geschlossen ist, kann er nicht neu eröffnet werden!")) {

                  await axios.get('/host/close');

                  localStorage.removeItem('host_id')
                  document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

                  location.href = process.env.VUE_APP_HOST_URL
                }
            }
        },
        computed: {
            inviteLink: function () {
                return this.$store.state.roomState.host ? process.env.VUE_APP_CLIENT_URL + '?hash=' + this.$store.state.roomState.host.hash : ''
            },
            inviteCode: function() {
                return this.$store.state.roomState.host ? this.$store.state.roomState.host.code : ''
            }
        },
        data: function () {
            return {
                roomName: "",
                isRegistered: false,
                roomSettings: {
                    roomOpen: true,
                    songTimeout: 60,
                    allowAdd: true,
                    allowVote: true,
                    autoConfirm: false,
                    autoDeleteThreshold: 99999999,
                    autoDeleteInterval: 99999999
                }
            }
        }
    }
</script>
<style scoped lang="stylus">

.createRoom {
  margin-top: 12rem;
}

    .createRoom input {
      max-width: 600px;
      font-size: 2rem;
      margin: 0 auto;
    }
.createRoom button {
  margin-top: 20px
}

    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 2rem;
      height: 1rem;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0.2rem;
      left: 0;
      right: 0;
      bottom: -0.2rem;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;

    }

    .slider:before {
      position: absolute;
      content: "";
      height: 0.8rem;
      width: 0.8rem;
      left: 0.1rem;
      bottom: 0.1rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #01b301;
    }


    input:checked + .slider:before {
      -webkit-transform: translateX(1rem);
      -ms-transform: translateX(1rem);
      transform: translateX(1rem);
    }


    .code
        letter-spacing 2px

    .custom-switch
        padding-bottom 10px
</style>