<template>
  <div v-if="loading" class="col-sm-12" style="text-align: center">
    <i class="fas fa-spinner fa-spin fa-7x"></i>
  </div>
  <div v-else class="container card-group">
    <div id="TopSongs" class="col-sm-12">
    <div class="c-grid-block">
      <h3 class="c-title">
              Beliebte Titel von {{result.name}}
            </h3>
      <div class="table table-hover">
        <tbody style="float: left; min-width: 50%">
          <tr v-for="track in result.top_track_objects.slice(0,4)">
            <td>
              <img :src="track.album.image" style="max-height: 3rem">
            </td>
            <td class="col-sm-12">
              <h6 class="td-overflow">{{ track.name }}</h6>
              <h6 class="text-50 td-overflow">{{track.album.name}}</h6>
            </td>
            <td>
              {{formatTime(track.duration)}}
            </td>
            <td>
            <span v-if="isInQueue(track.track_id)">
              <a class="btn btn">
                <i class="fas fa-check" style="color: green"></i>
              </a>
            </span>
              <a class="btn btn" v-else @click="addTrack(track.track_id)">
                <i class="fa fa-plus text-100"></i></a>
            </td>
          </tr>
          <tr v-for="index in (4 - result.top_track_objects.slice(0,4).length)">
          </tr>
          </tbody>
        <tbody>
        <tr v-for="track in result.top_track_objects.slice(4,8)">
          <td>
            <img :src="track.album.image" style="max-height: 3rem">
          </td>
          <td class="col-sm-7">
            <h6 class="td-overflow">{{ track.name }}</h6>
            <h6 class="text-50 td-overflow">{{track.album.name}}</h6>
          </td>
          <td>
            {{formatTime(track.duration)}}
          </td>
          <td>
            <span v-if="isInQueue(track.track_id)">
              <a class="btn btn">
                <i class="fas fa-check" style="color: green"></i>
              </a>
            </span>
            <a class="btn btn" v-else @click="addTrack(track.track_id)">
              <i class="fa fa-plus text-100"></i></a>
          </td>
        </tr>
        <tr v-for="index in (4 - result.top_track_objects.slice(4,8).length)">
        </tr>
        </tbody>
        </div>
      </div>
      </div>
    <div class="col-sm-12">
      <br/>
    </div>

        <div id="Diskographie" class="col-sm-12">
            <div class="c-grid-block">
                <h3 class="c-title">
                    Alben
                </h3>
                <div class="card-deck">
                    <div class="card" style="width: 13rem;" v-for="album in result.albums.slice(0,5)">
                        <div class="card-deck">
                        </div>
                        <img class="card-img-top" :src="album.image" alt="Card image cap">
                        <div class="card-body">
                            <a :href="'/albums/' + album.album_id" class="stretched-link">
                                <h5 class="card-link">{{ album.name }}</h5>
                            </a>
                        </div>
                    </div>
                    <div class="card-empty" style="width: 13rem;"
                         v-for="index in (5 - result.albums.slice(0,5).length)">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'SearchArtist',
        components: {},

        /** Lifecycle Events - Wird ausgeführt wenn die Seite geladen (created) wird **/
        created: async function () {
            this.onSearch()
        },

        /** Daten welche der View <template> zur Verfügung gestellt werden **/
        data: function () {
            return {
                loading: true,
                result: {}
            }
        },
        watch: {
            //  '$route.params.query': function () {
            //  this.onSearch()
            // },
            '$route.params.artist_id': function () {
                this.onSearch()
            }
        },

        /** Methoden welche aufgerufen werden können
         * Entweder im <script> mit this.methodName()
         * Oder im Template
         */
        methods: {

            getAlbumfromAlbum_id (album_id) {
                const album = this.result.albums.find(function (element) {
                    return element.album_id === album_id
                })
                return album
            },

            async onSearch () {
                const searchText = this.$route.params.query
                const artistId = this.$route.params.artist_id

                this.loading = true
                //  const result = await axios.get('/data/search?query=' + searchText)
                const result = await axios.get('/data/artist/' + artistId)
                // const result = await axios.get('/data/album/' + albumId)
                this.result = result.data
                this.loading = false
            }
        }
    }
</script>

<style scoped>


.text-100 {
  color: #000000;
}

.text-50 {
  color: rgba(0,0,0,0.5);
  font-weight: inherit;
}
.card-empty {
  backface-visibility: hidden;
}

.table td {
  vertical-align: middle;
}

.card-body {
  padding: 1rem;

}

.card-link{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.td-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.c-title {
  margin-left: 1rem;
  padding-top: 0.5rem;
}

.c-grid-block {
  background-color: rgba(126, 186, 118,0.15);
  color: #000000;
  /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
  */
}

.table {
  color: #0b1f0b;
  width: 100%;
  margin-bottom: 0rem ;

}

.stretched-link {
  color: #0b1f0b;
  text-decoration: none ;
}

.card-deck {
  margin-left: 1rem;
  margin-right: 1rem;

}

.card-deck .card {
  margin-bottom: 1rem;
}


.table-hover tbody tr:hover td {
  background-color: rgba(69, 153, 55, 0.2);
}

.card:hover {
  background-color: rgba(69, 153, 55,0.2);
}

.text-100 {
  color: #000000;
}

.text-50 {
  color: rgba(0,0,0,0.5);
  font-weight: inherit;
}


.card {
  background: rgba(255, 255, 255,0);
  border-radius: 0px;
  border: 1rem solid rgba(255,255,255,0);
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.1);
}

</style>


