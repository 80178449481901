import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan, faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBan, faMinus, faPlus, faTrash)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL
axios.defaults.withCredentials = true

Vue.mixin({
  methods: {
    getClientName(client_id) {
      var client = null

      this.$store.state.roomState.clients.forEach(cl => {
        if (cl.client_id == client_id) {
          client = cl.nickname
        }
      })

      return client
    },
    addTrack(track_id) {
      this.$store.dispatch('addToQueue', track_id)
    },
    isInQueue(track_id) {
      if (! this.$store.state.roomState.queue) {
        return false
      }

      return this.$store.state.roomState.queue_short.indexOf(track_id) !== -1
    },
    formatTime(miliseconds) {
      const duration = Math.floor(miliseconds / 1000)

      const minutes = Math.floor(duration / 60);
      let seconds = duration - (minutes * 60);

      if (seconds < 10) {
        seconds = seconds + "0"
      }

      return minutes + ":" + seconds
    },
    vote: function(entry_id, delta) {
      this.$store.dispatch('vote', {
        entry_id: entry_id,
        delta: delta
      })
    }
  },
  computed: {
    statusLoaded: function() {
      return this.$store.state.roomState.clients
    },
    sortedQueue: function() {
      let queue = this.$store.state.roomState.queue

      if (! queue) {
        console.log('no queue yet')
        return []
      }

      queue.sort((a, b) => {
        if (a.locked_until && !b.locked_until) {
          return 1
        }
        if (b.locked_until && !a.locked_until) {
          return -1
        }

        return 0
      })

      queue.sort((a, b) => {
        if (a.votes !== b.votes) {
          return a.votes > b.votes ? -1 : 1
        }
        else {
          const dateA = new Date(a.updated_at)
          const dateB = new Date(b.updated_at);

          // console.log(dateA.getTime() - dateB.getTime())
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA > dateB ? 1 : - 1
          }
          else {
            const createdA = new Date(a.created_at)
            const createdB = new Date(b.created_at);

            if (createdA.getTime() === createdB.getTime()) {
              return a.entry_id > b.entry_id ? 1 : -1
            }
            else {
              return createdA > createdB ? 1 : - 1
            }
          }
        }
      })

      return queue
    }
  }
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
