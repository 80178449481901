<template>
  <div>
    <div v-if="loading" class="col-sm-12" style="text-align: center">
      <i class="fas fa-spinner fa-spin fa-7x"></i>
    </div>
    <div v-else class="container card-group">
      <div id="TopArtist" class="col-sm-4">
        <div class="c-grid-block" style="height: 22.9rem">
          <h3 class="c-title" >Top Künstler*innen
          </h3>
          <div class="card" style="margin-left: 1rem; margin-right: 1rem; max-height: 19rem" v-if="result.artists.length > 0">
            <div class="card-deck">
            <img class="card-img-top-big" style="margin-top: 1rem " :src="result.artists[0].image" alt="Card image cap">
            <div class="card-body">
              <a :href="'/artists/' +result.artists[0].artist_id" class="stretched-link">
                <h5 class="td-overflow">{{ result.artists[0].name }}</h5>
              </a>
            </div>
            </div>
          </div>
          <div class="card" style="margin-left: 1rem; margin-right: 1rem; max-height: 19rem" v-else>
            <div class="card-deck"></div>
           <img class="card-img-top-big" src="../../assets/ManyDJ_Logo.png">
            <div class="card-body">
              <a class="stretched-link">
                <h5 class="td-overflow" style="visibility: hidden"> default</h5>
              </a>
              <p class="text-50">Kein Künstler*in verfügbar</p>
            </div>
          </div>
        </div>
      </div>
      <div id="Songs" class="col-sm-8">
        <div class="c-grid-block" style="height: 22.9rem">
          <h3 class="c-title">Songs
          </h3>
          <div class="table table-hover">
            <tbody>
            <tr v-for="track in result.tracks.slice(0,4)">
              <td>
                <img :src="track.album.image" style="max-height: 3rem">
              </td>
              <td class="col-sm-7">
                <h6 class="td-overflow">{{ track.name }}</h6>
                <h6 class="text-50 td-overflow">{{track.album.name}}</h6>
              </td>
              <td>
                {{formatTime(track.duration)}}
              </td>
              <td>
                                <span v-if="isInQueue(track.track_id)">
                                   <a class="btn btn">
                                   <i class="fas fa-check" style="color: green"></i>
                                   </a>
                                </span>
                <a class="btn btn" v-else @click="addTrack(track.track_id)">
                  <i class="fa fa-plus text-100" ></i></a>
              </td>
            </tr>
            </tbody>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <br/>
      </div>
      <div id="OtherArtists" class="col-sm-12">
        <div class="c-grid-block">
          <h3 class="c-title">
            Andere Künstler*innen
          </h3>
          <div class="card-deck">
            <div class="card" style="width: 13rem;" v-for="artist in result.artists.slice(0,5)">
              <div class="card-deck">
              </div>
              <img class="card-img-top-sm" v-if="artist.image!=='no-image'" :src="artist.image">
              <img class="card-img-top-sm" v-else  src="../../assets/ManyDJ_Logo.png">
              <div class="card-body">
                <a :href="'/artists/' +artist.artist_id " class="stretched-link">
                  <h5 class="card-link">{{artist.name}}</h5>
                </a>
              </div>
            </div>
            <div class="card-empty" style="width: 13rem;" v-for="index in (5 - result.artists.slice(0,5).length)">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <br/>
      </div>
      <div id="Alben" class="col-sm-12">
        <div class="c-grid-block">
          <h3 class="c-title">
            Alben
          </h3>
          <div class="card-deck">
            <div class="card" style="width: 13rem;" v-for="album in result.albums.slice(0,5)">
              <div class="card-deck">
              </div>
              <img class="card-img-top-sm" v-if="album.image!=='no-image'" :src="album.image" alt="Card image cap">
              <img class="card-img-top-sm" v-else  src="../../assets/ManyDJ_Logo.png">
              <div class="card-body">
                <a :href="'/albums/' + album.album_id" class="stretched-link">
                  <h5 class="card-link">{{ album.name }}</h5>
                </a>
              </div>
            </div>
            <div class="card-empty" style="width: 13rem;" v-for="index in (5 - result.albums.slice(0,5).length)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Search',
  components: {},

  /** Lifecycle Events - Wird ausgeführt wenn die Seite geladen (created) wird **/
  created: async function () {
    this.onSearch()
  },

  /** Daten welche der View <template> zur Verfügung gestellt werden **/
  data: function() {
    return {
      loading: true,
      result: {}
    }
  },
  watch: {
    '$route.params.query': function () {
      this.onSearch()
    }
  },

  /** Methoden welche aufgerufen werden können
   * Entweder im <script> mit this.methodName()
   * Oder im Template
   */
  methods: {
    async onSearch () {
      const searchText = this.$route.params.query

      if (!searchText) {
        this.$router.push('/queue')
      }

      this.loading = true
      const result = await axios.get('/data/search?query=' + searchText)
      // const result = await axios.get('/data/artists/' + artistId)
      // const result = await axios.get('/data/album/' + albumId)

      if (result.data.redirect) {
        const url = result.data.redirect.replace("artist", "artists");
        this.$router.push(url)
      }

      this.result = result.data
      this.loading = false
    }
  }
}

</script>

<style scoped>
.col-sm-4 {


}

.text-100 {
color: #000000;
}

.text-50 {
  color: rgba(0,0,0,0.5);
  font-weight: inherit;
}
.card-empty {
  backface-visibility: hidden;
}

.table td {
  vertical-align: middle;
}

.card-body {
  padding: 1rem;

}

.card-link{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.td-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.c-title {
  margin-left: 1rem;
  padding-top: 0.5rem;
}

.c-grid-block {
  background-color: rgba(126, 186, 118,0.15);
  color: #000000;
/* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
*/
}



.stretched-link {
  color: #0b1f0b;
  text-decoration: none ;
}

.card-deck {
  margin-left: 1rem;
  margin-right: 1rem;

}


.card-deck .card {
  margin-bottom: 1rem;
}
.card-transparent{

}

.card-transparent:hover{
  background-color: rgba(69, 153, 55, 0.2);
}
.table-hover tbody tr:hover td {
  background-color: rgba(69, 153, 55, 0.2);
}

.card:hover {
  background-color: rgba(69, 153, 55,0.2);
}
.card-img-top-sm{
  min-width: 100%;
  max-height: 9rem;
  object-fit: cover;

  /*
  width: auto;
   max-height: 11rem;
   object-fit:cover;
   */


}

.card-img-top-big{
  max-height: 13rem;
  max-width: 13rem;

}


.card {
  background: rgba(255, 255, 255,0);
  border-radius: 0px;
  border: 1rem solid rgba(255,255,255,0);
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.1);
}

.logo-small {
  display: block;
  margin-left: auto;
  margin-right: 0;
  width: 20%;
}


</style>




