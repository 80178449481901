import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        roomState: {},
        queue: [],
        hostid: null,
        freezeState: false
    },
    mutations: {
        setStatus: (state, newStatus) => {
            if (!state.freezeState) {
                state.roomState = newStatus
            } else {
                console.log('status skipped, state is frozen')
            }
        },
        setQueue: (state, queue) => {
            state.queue = queue
        },
        setHostId: (state, payload) => state.hostid = payload,
        freeze: (state) => state.freezeState = true,
        unFreeze: (state) => state.freezeState = false,
    },
    actions: {
        addToQueue ({commit, state}, track_id) {
            const currentState = state.roomState

            currentState.queue_short.push(track_id)

            commit('setStatus', currentState)
            commit('freeze')

            axios.post('/host/addTrack', {track_id: track_id}).then((response) => {
                commit('unFreeze') // open the state for new changes
            })
        },
        vote ({commit, state}, data) {
            const currentState = state.roomState

            currentState.queue.forEach(el => {
                if (el.entry_id === data.entry_id) {
                    el.votes += data.delta
                    el.updated_at = new Date()
                }
            })
            commit('setStatus', currentState)

            // TODO: send to server
            commit('freeze')

            axios.post('/host/vote', data).then((response) => {
                commit('unFreeze') // open the state for new changes
            })
        }
    },
    modules: {}
})
