import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SearchArtist from "@/views/Search/SearchArtist";
import SearchMain from "@/views/Search/SearchMain";
import SearchAlbum from "@/views/Search/SearchAlbum";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/queue',
    name: 'Queue',
    component: function () {
      return import('../views/Queue.vue')
    }
  },
  {
    path: '/display/:hash',
    name: 'Display',
    component: function () {
      return import('../views/Display.vue')
    }
  },
  {
    path: '/playlists',
    name: 'Playlists',
    component: function () {
      return import('../views/Playlists.vue')
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: function () {
      return import('../views/Clients.vue')
    }
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: function () {
      return import('../views/Waitlist.vue')
    }
  },
  {
    path: '/search/:query',
    name: 'Search',
    component: SearchMain
  },
  {
    path: '/artists/:artist_id',
    name: 'SearchArtist',
    component: SearchArtist
  },
  {
    path: '/albums/:album_id',
    name: 'SearchAlbum',
    component: SearchAlbum

  }
  // {
  //   path: '/search',
  //   name: 'Search',
  //   component: function () {
  //     return import('../views/SearchMain.vue')
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
